import Layout from "../components/layout";
import {Helmet} from "react-helmet";
import {XLHeader, P} from "../components/typography";
import React from "react";

const NotFoundPage = ({location}) => {
  return (
    <Layout location={location}>
      <div>
        <Helmet>
          <title>Page Not Found</title>
        </Helmet>
        <XLHeader className="p-8 md:p-16 text-white">Page Not Found</XLHeader>
        <P className="pl-8 pb-8 md:pl-16 md:pb-16 text-white">Think there should be a page here? Please reach out to <a className="underline text-yellow" href="mailto:it@kingcountyequitynow.com">it@kingcountyequitynow.com</a> and we will get back to you.</P>
      </div>
    </Layout>
  )
}

export default NotFoundPage;
